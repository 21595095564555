import axios from 'axios';

const HOST = process.env.REACT_APP_API_SERVER_URL;

const fetchFromApi = async (endpoint, method, body, config = {}) => {
	const token = localStorage.getItem('accessToken');
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	};

	try {
		const response = await axios({
			method,
			url: `${HOST}/api${endpoint}`,
			headers,
			data: body,
		});
		return { data: response.data, error: null };
	} catch (error) {
		throw new Error(error.response.data.message || 'Something went wrong');
	}
};

export default fetchFromApi;
