import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import LimelineLogoDark from '../../images/limeline-dark.svg';
import UserAvatar from '../../images/user-avatar.jpeg';
import { logoutUser } from '../../redux/features/user/action';
import styles from './index.module.css';

function NavBar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector((state) => state.user);
	const accessToken = localStorage.getItem('accessToken');
	const [activeLink, setActiveLink] = useState(location.pathname);
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (!accessToken) {
			navigate('/');
		}
	}, [accessToken, navigate]);

	const handleLogout = () => {
		dispatch(logoutUser());
	};

	return (
		<Navbar
			bg="light"
			expand="lg"
			style={{
				backgroundColor: '#fff',
				boxShadow: '0 0 10px rgba(0,0,0,0.2)',
				zIndex: 100,
			}}
		>
			<Container>
				<Navbar.Brand href="" onClick={() => navigate('/home')}>
					<img
						src={LimelineLogoDark}
						width="30"
						height="30"
						className="d-inline-block align-top"
						alt="Logo"
						style={{ height: '30px', width: 'auto', cursor: 'pointer' }}
					/>
				</Navbar.Brand>
				<Navbar.Toggle
					aria-controls="basic-navbar-nav"
					onClick={() => setExpanded(!expanded)}
				/>

				<Navbar.Collapse
					id="basic-navbar-nav"
					className="justify-content-end"
					expanded={expanded}
				>
					<Nav className="me-auto align-items-center">
						<Nav.Link
							href="#"
							onClick={() => {
								setActiveLink('/home');
								navigate('/home');
							}}
							style={{
								marginLeft: '10px',
								color: activeLink === '/home' ? '#01334B' : '#000000A6',
							}}
						>
							My Meetings
						</Nav.Link>
						<Nav.Link
							href="#"
							onClick={() => {
								setActiveLink('/agents');
								navigate('/agents');
							}}
							style={{
								color: activeLink === '/agents' ? '#01334B' : '#000000A6',
							}}
						>
							My Agents
						</Nav.Link>
						{user?.organization?.plan?.type !== 'Free' && (
							<Nav.Link
								href="#"
								onClick={() => {
									setActiveLink('/google-meetings');
									navigate('/google-meetings');
								}}
								style={{
									color:
										activeLink === '/google-meetings' ? '#01334B' : '#000000A6',
								}}
							>
								Google Meetings
							</Nav.Link>
						)}
						<Nav.Link
							href="#"
							style={{
								color: activeLink === '/settings' ? '#01334B' : '#000000A6',
							}}
							onClick={() => {
								setActiveLink('/settings');
								navigate('/settings');
							}}
						>
							Settings
						</Nav.Link>
						<Nav.Link className="d-sm-none" href="mailto:support@limeline.ai">
							Support
						</Nav.Link>
						<Nav.Link
							className="d-sm-none"
							href="#"
							onClick={() => handleLogout()}
							style={{
								color: '#000000A6',
							}}
						>
							Logout
						</Nav.Link>
					</Nav>

					{/* {expanded && ( */}

					<Nav className="d-none d-sm-block">
						<NavDropdown
							title={
								<img
									src={user && user.picture ? user.picture : UserAvatar}
									alt="User"
									style={{
										width: '30px',
										height: '30px',
										borderRadius: '75%',
									}}
								/>
							}
							id="basic-nav-dropdown"
							style={{
								marginRight: '30px',
							}}
							align="end"
							className={styles.navdropdown}
						>
							<NavDropdown.Item href="/settings">
								Account Settings
							</NavDropdown.Item>
							<NavDropdown.Item href="mailto:support@limeline.ai">
								Support
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#" onClick={() => handleLogout()}>
								Logout
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavBar;
