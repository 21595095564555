import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	keys: [],
	isLoading: false,
	error: null,
};

const apikeySlice = createSlice({
	name: 'apikey',
	initialState,
	reducers: {
		fetchApikeyStart: (state) => {
			state.isLoading = true;
		},
		fetchApikeySuccess: (state, action) => {
			state.isLoading = false;
			state.keys = action.payload;
		},
		fetchApikeyFailure: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const { fetchApikeyStart, fetchApikeySuccess, fetchApikeyFailure } =
	apikeySlice.actions;

export default apikeySlice.reducer;
