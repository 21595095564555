import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	_id: '',
	given_name: '',
	family_name: '',
	name: '',
	email: '',
	picture: '',
	accessToken: '',
	isLoading: false,
	error: null,
	organization: {
		_id: '',
		name: '',
		plan: {},
		logo: '',
		color: {},
		createDate: '',
	},
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLoading: (state, payload) => {
			state.isLoading = payload;
		},
		setAccountData: (state, action) => {
			const {
				_id,
				given_name,
				family_name,
				name,
				email,
				picture,
				accessToken,
				organization,
			} = action.payload;
			state._id = _id;
			state.given_name = given_name;
			state.family_name = family_name;
			state.name = name;
			state.email = email;
			state.picture = picture;
			state.accessToken = accessToken;
			state.isLoading = false;
			state.organization = organization;
		},
	},
});

export const { setAccountData, setLoading } = userSlice.actions;

export default userSlice.reducer;
