import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	agents: [],
	selectedAgent: null,
	isLoading: false,
	error: null,
};

const agentSlice = createSlice({
	name: 'agent',
	initialState,
	reducers: {
		fetchAgentsStart: (state) => {
			state.isLoading = true;
		},
		setSelectedAgent: (state, action) => {
			state.selectedAgent = action.payload;
		},
		fetchAgentsSuccess: (state, action) => {
			state.isLoading = false;
			state.agents = action.payload;
		},
		fetchAgentsFailure: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchAgentsStart,
	fetchAgentsSuccess,
	fetchAgentsFailure,
	setSelectedAgent,
} = agentSlice.actions;

export default agentSlice.reducer;
