import axios from 'axios';
import { setAccountData, setLoading } from './slice';
import fetchFromApi from '../../../services/apiService';

const HOST = process.env.REACT_APP_API_SERVER_URL;

export const loadUserDataFromDB = () => async (dispatch) => {
	const accessToken = localStorage.getItem('accessToken');

	if (!accessToken) {
		return;
	}

	dispatch(setLoading(true));

	try {
		const { data, error } = await fetchFromApi(`/user/account`, 'GET');
		if (error) throw new Error(error);

		const user = JSON.parse(localStorage.getItem('googleUser'));

		const userAndData = {
			...user,
			...data,
		};

		localStorage.setItem('googleUser', JSON.stringify(userAndData));
		dispatch(setAccountData(userAndData));
	} catch (error) {
		console.error(error.message);
	}
};

export const loginUser = (user) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const { data, error } = await fetchFromApi(`/user/authenticate`, 'POST', {
			user,
		});
		if (error) throw new Error(error);

		localStorage.setItem('accessToken', data.accessToken);

		const userAndData = {
			...user,
			...data,
			given_name: user.given_name,
			family_name: user.family_name,
			name: user.name,
			picture: user.picture,
		};

		localStorage.setItem('googleUser', JSON.stringify(userAndData));
		dispatch(setAccountData(userAndData));
		// loadUserDataFromDB
		dispatch(loadUserDataFromDB());
	} catch (error) {
		console.error(error.message);
	}
};

export const logoutUser = () => async (dispatch) => {
	dispatch(setAccountData({}));
	localStorage.removeItem('accessToken');
	localStorage.removeItem('googleUser');
};

export const updateAccountInfo =
	(name, organizationName, color) => async (dispatch) => {
		try {
			const { data, error } = await fetchFromApi('/user/account', 'PUT', {
				name,
				organizationName,
				color,
			});
			if (error) throw new Error(error);
			dispatch(loadUserDataFromDB());
		} catch (error) {
			console.error(error.message);
		}
	};

export const uploadOrganisationLogo = (organizationId, logo) => async () => {
	try {
		const formData = new FormData();
		formData.append('logo', logo);

		const token = localStorage.getItem('accessToken');
		const headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		};

		const response = await axios.post(
			`${HOST}/api/user/account/${organizationId}/logo/add`,
			formData,
			{
				headers,
			}
		);

		if (response.status !== 200) {
			throw new Error(response.data.message || 'Could not upload the logo.');
		}

		return response.data;
	} catch (error) {
		console.error(error.message);
	}
	return null;
};

export const removeOrganisationLogo = (organizationId) => async () => {
	try {
		const { data, error } = await fetchFromApi(
			`/user/account/${organizationId}/logo/remove`,
			'POST'
		);
		if (error) throw new Error(error);
		return data;
	} catch (error) {
		console.error(error.message);
	}
	return null;
};
