import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	meetings: [],
	isLoading: false,
	error: null,
	currentMeeting: null,
	currentMeetingCalls: [],
	currentMeetingSession: {
		meeting: null,
		isLoading: false,
		error: false,
	},
	callStartingTime: null,
};

const meetingSlice = createSlice({
	name: 'meeting',
	initialState,
	reducers: {
		fetchMeetingsStart: (state) => {
			state.isLoading = true;
		},
		fetchMeetingsSuccess: (state, action) => {
			state.isLoading = false;
			state.meetings = action.payload;
		},
		fetchMeetingsFailure: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
		setCurrentMeeting: (state, action) => {
			state.isLoading = false;
			state.currentMeeting = action.payload;
		},
		setCurrentMeetingCalls: (state, action) => {
			state.isLoading = false;
			state.currentMeetingCalls = action.payload;
		},
		setCurrentMeetingSession: (state, action) => {
			state.currentMeetingSession.meeting = action.payload;
			const defaultLanguage = action.payload?.languages[0];
			state.currentMeetingSession.selectedLanguage = defaultLanguage;
			state.currentMeetingSession.isLoading = false;
			state.currentMeetingSession.error = null;
		},
		setSelectedLanguage: (state, action) => {
			state.currentMeetingSession.selectedLanguage = action.payload;
		},
		setCurrentMeetingSessionError: (state) => {
			state.currentMeetingSession.isLoading = false;
			state.currentMeetingSession.error = true;
		},
		setCurrentMeetingSessionLoading: (state) => {
			state.currentMeetingSession.isLoading = true;
		},
		setCallStartingTime: (state, action) => {
			state.callStartingTime = action.payload;
		},
	},
});

export const {
	fetchMeetingsStart,
	fetchMeetingsSuccess,
	fetchMeetingsFailure,
	setCurrentMeeting,
	setCurrentMeetingCalls,
	setCurrentMeetingSession,
	setCurrentMeetingSessionError,
	setCurrentMeetingSessionLoading,
	setCallStartingTime,
	setSelectedLanguage,
} = meetingSlice.actions;

export default meetingSlice.reducer;
