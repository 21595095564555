import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Button, Row, Col, Modal } from 'react-bootstrap';
import { MdAdd, MdCall } from 'react-icons/md';
import NewVirtualParticipantMeetingModal from '../../components/NewVirtualParticipantMeetingModal';
import NavBar from '../../components/NavBar';
import EmptyState from '../../components/EmptyState';
import Plans from '../Pricing/components/Plans';
import humanizeDate from '../../utils/humanizeDate';
import { fetchMeetings } from '../../redux/features/virtual-participant-meeting/action';

function VirtualParticipantMeetings() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const meetings = useSelector(
		(state) => state.virtualParticipantMeeting.meetings
	);
	const [modalPlansShow, setModalPlansShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [showEmail, setShowEmail] = useState(false);

	useEffect(() => {
		dispatch(fetchMeetings());
	}, [dispatch]);

	const handleNewMeeting = () => {
		// if (
		// 	(user?.organization?.plan?.meetingCredits?.total || 0) -
		// 		(user?.organization?.plan?.meetingCredits?.used || 0) >
		// 	0
		// ) {
		// 	setModalShow(true);
		// } else {
		// 	setModalPlansShow(true);
		// }
		setModalShow(true);
	};

	return (
		<div>
			<NavBar />
			<Container>
				<div className="d-flex justify-content-between align-items-center mt-4 mb-4">
					<h5>Google Meet - Agent Meetings</h5>
					<Button
						variant="secondary"
						className="mb-4"
						onClick={() => handleNewMeeting()}
					>
						<MdAdd /> New Meeting
					</Button>
					<NewVirtualParticipantMeetingModal
						show={modalShow}
						handleClose={() => {
							setModalShow(false);
							setShowEmail(false);
						}}
						showEmail={showEmail}
						setShowEmail={setShowEmail}
					/>
				</div>
				<Row>
					{meetings
						.slice(0)
						.reverse()
						.map((meeting) => (
							<Col
								key={meeting._id}
								xs={12}
								sm={6}
								md={4}
								lg={3}
								onClick={() => navigate(`/google-meetings/${meeting._id}`)}
								style={{
									cursor: 'pointer',
								}}
							>
								<Card className="mb-4 card-green-top">
									<Card.Body className="d-flex justify-content-between">
										<div>
											<h5>{meeting.name}</h5>
											<p
												// className="mb-5"
												style={{
													fontSize: 14,
													color: '#656565',
												}}
											>
												Created on {humanizeDate(meeting.createdAt)}
											</p>
											{/* <p style={{ fontSize: 14 }}>
												<span>
													<MdCall color="primary" />
												</span>
												&nbsp;10
												<span style={{ color: '#656565' }}> calls taken</span>
											</p> */}
										</div>
									</Card.Body>
								</Card>
							</Col>
						))}
					{/* Empty state - center aligned message */}
					{meetings.length === 0 && (
						<Col className="text-center mt-4">
							<EmptyState
								title="No meetings yet"
								body="Create a new meeting to get started."
								actionName="New Meeting"
								action={() => handleNewMeeting()}
							/>
						</Col>
					)}
				</Row>
			</Container>
			<Modal
				show={modalPlansShow}
				onHide={() => setModalPlansShow(false)}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Subscribe</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4 className="text-center">Automate all meetings</h4>
					<p className="text-center">
						You have no credits left. Please upgrade to create more meetings.
					</p>
					<Plans />
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default VirtualParticipantMeetings;
