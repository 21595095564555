import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import getWeekFromDate from '../../utils/getWeekFromDate';
import { addMeeting } from '../../redux/features/virtual-participant-meeting/action';

toast.configure();
dayjs.extend(utc);
dayjs.extend(timezone);

function NewVirtualParticipantMeetingModal({
	show,
	handleClose,
	showEmail,
	setShowEmail,
}) {
	const dispatch = useDispatch();
	const [meetingName, setMeetingName] = useState('');
	const [meetLink, setMeetLink] = useState('');
	const [meetingDateTime, setMeetingDateTime] = useState(dayjs());
	const [repeat, setRepeat] = useState('none');

	const handleDateChange = (e) => {
		const newDate = dayjs(e.target.value);
		setMeetingDateTime(newDate);
	};

	const handleAddMeeting = (e) => {
		e.preventDefault();
		if (meetingName === '' || meetLink === '' || meetingDateTime === '') {
			toast.error('All fields are required.');
			return;
		}
		dispatch(
			addMeeting({
				name: meetingName,
				meetingUrl: meetLink,
				meetingDateTime: meetingDateTime.utc().format(),
				timezone: dayjs.tz.guess(),
				repeat,
			})
		);
		setMeetingName('');
		setMeetLink('');
		setMeetingDateTime(dayjs());
		setRepeat('none');
		setShowEmail(true);
	};

	const handleCopyEmail = (e) => {
		e.preventDefault();
		navigator.clipboard.writeText('samantha@limeline.ai');
		toast.success('Email copied.');
		setShowEmail(false);
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>New Meeting</Modal.Title>
			</Modal.Header>
			{showEmail === false && (
				<>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="meetLink">
							<Form.Label>Meeting Name</Form.Label>
							<Form.Control
								value={meetingName}
								onChange={(e) => setMeetingName(e.target.value)}
								required
								placeholder="Enter meeting name"
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="meetLink">
							<Form.Label>Google Meet Link</Form.Label>
							<Form.Control
								value={meetLink}
								onChange={(e) => setMeetLink(e.target.value)}
								required
								placeholder="e.g. https://meet.google.com/..."
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="dateTime">
							<Form.Label>Date and Time</Form.Label>
							<Form.Control
								type="datetime-local"
								value={meetingDateTime.format('YYYY-MM-DDTHH:mm')}
								onChange={handleDateChange}
							/>
						</Form.Group>
						<Form.Group controlId="repeat">
							<Form.Label>Repeat</Form.Label>
							<Form.Select
								value={repeat}
								onChange={(e) => setRepeat(e.target.value)}
							>
								<option value="none">Does not repeat</option>
								<option value="daily">Daily</option>
								<option value="weekly">
									Weekly on {meetingDateTime.format('dddd')}
								</option>
								<option value="monthly">
									{/* Monthly on the {getWeekFromDate(meetingDateTime)}{' '}
									{meetingDateTime.format('dddd')} */}
									Monthly on {meetingDateTime.format('D')}
								</option>
								<option value="yearly">
									Yearly on {meetingDateTime.format('MMMM D')}
								</option>
								<option value="weekdays">
									Every Weekday (Monday to Friday)
								</option>
								{/* Add custom option later */}
							</Form.Select>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-between">
						<Button
							variant="primary-outline"
							type="button"
							className="rounded-pill"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button variant="primary" type="submit" onClick={handleAddMeeting}>
							Save Meeting
						</Button>
					</Modal.Footer>
				</>
			)}
			{showEmail === true && (
				<>
					<Modal.Body>
						<Form.Group controlId="meetLink">
							<Form.Label>
								Add the following email to this meeting to be able to record
								calls and make the AI agent participate:
							</Form.Label>
							<Form.Control value="samantha@limeline.ai" disabled />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-between">
						<Button
							variant="primary-outline"
							type="button"
							className="rounded-pill"
							onClick={handleClose}
						>
							Finish
						</Button>
						<Button variant="primary" type="submit" onClick={handleCopyEmail}>
							Copy Email
						</Button>
					</Modal.Footer>
				</>
			)}
		</Modal>
	);
}

NewVirtualParticipantMeetingModal.propTypes = {
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	showEmail: PropTypes.bool.isRequired,
	setShowEmail: PropTypes.func.isRequired,
};

export default NewVirtualParticipantMeetingModal;
