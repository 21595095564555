import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => (
	<div className="d-flex justify-content-center align-items-center vh-100">
		<Spinner animation="border" variant="success" />
	</div>
);

export default Loader;
