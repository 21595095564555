/* eslint-disable import/prefer-default-export */
import fetchFromApi from '../../../services/apiService';

// create session
export const createSession = (payload) => async (dispatch) => {
	try {
		const { data, error } = await fetchFromApi(
			'/payment/session/create',
			'POST',
			payload
		);
		if (error) throw new Error(error);
		return data;
	} catch (error) {
		console.error(error.message);
		return null;
	}
};
