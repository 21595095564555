import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { MdAddCircle, MdDelete, MdEditSquare } from 'react-icons/md';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
	fetchCurrentMeeting,
	joinMeetingNow,
	deleteMeeting,
	fetchCurrentMeetingCalls,
} from '../../redux/features/virtual-participant-meeting/action';
import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
// import getWeekFromDate from '../../utils/getWeekFromDate';
import UpdateVirtualParticipantMeetingModal from '../../components/UpdateVirtualParticipantMeetingModal';

dayjs.extend(utc);
dayjs.extend(timezone);

function VirtualParticipantMeeting() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { meetingId } = useParams();
	const { currentMeeting, currentMeetingCalls, loading } = useSelector(
		(state) => state.virtualParticipantMeeting
	);
	const [modalShow, setModalShow] = useState(false);

	//   fetch meeting only once
	useEffect(() => {
		dispatch(fetchCurrentMeeting(meetingId));
		dispatch(fetchCurrentMeetingCalls(meetingId));
	}, [dispatch]);

	if (loading) {
		return <Loader />;
	}

	function displayRepeat() {
		switch (currentMeeting?.repeat) {
			case 'daily':
				return 'Repeat Daily';
			case 'weekly':
				return `Repeat weekly on ${dayjs(
					currentMeeting?.meetingDateTime
				).format('dddd')}`;
			case 'monthly':
				// return `Repeat monthly on the ${getWeekFromDate(
				// 	currentMeeting?.meetingDateTime
				// )} ${dayjs(currentMeeting?.meetingDateTime).format('dddd')}`;
				return `Repeat monthly on ${dayjs(
					currentMeeting?.meetingDateTime
				).format('D')}`;
			case 'yearly':
				return `Repeat yearly on ${dayjs(
					currentMeeting?.meetingDateTime
				).format('MMMM D')}`;
			case 'weekdays':
				return 'Repeat every weekday (Monday to Friday)';
			default:
				return 'Does not repeat';
		}
	}

	return (
		<div className="d-flex flex-column vh-100">
			<NavBar />
			<div className="flex-grow-1 d-flex flex-column gap-3 align-items-center justify-content-center p-3 bg-grey-200">
				<div className="col-4 p-3 border border-dark rounded bg-white">
					<div className="d-flex justify-content-between gap-3">
						<div>
							<div className="h5">{currentMeeting?.name}</div>
							<div className="h6">{currentMeeting?.meetingUrl}</div>
							<div className="h6">
								{dayjs(currentMeeting?.meetingDateTime).format('MMMM-DD, YYYY')}
								&nbsp;|&nbsp;
								{dayjs(currentMeeting?.meetingDateTime).format('hh:mm a')}
							</div>
							<div className="h6">{currentMeeting?.timezone}</div>
							<div className="h6">{displayRepeat()}</div>
							<div className="h6">
								Next Meeting:&nbsp;
								{dayjs(currentMeeting?.nextMeetingDateTime).format(
									'MMMM-DD, YYYY'
								)}
								&nbsp;|&nbsp;
								{dayjs(currentMeeting?.nextMeetingDateTime).format('hh:mm a')}
							</div>
						</div>
						<div className="d-flex flex-column gap-2">
							<Button
								variant="outline-primary"
								className="d-flex align-items-center gap-1"
								onClick={() => {
									dispatch(joinMeetingNow(meetingId));
								}}
							>
								<MdAddCircle size={18} />
								<span className="fw-bold">Join Meeting Now</span>
							</Button>
							<Button
								variant="outline-secondary"
								className="d-flex align-items-center gap-1"
								onClick={() => {
									setModalShow(true);
								}}
							>
								<MdEditSquare size={18} />
								<span className="fw-bold">Update Meeting</span>
							</Button>
							<UpdateVirtualParticipantMeetingModal
								show={modalShow}
								handleClose={() => setModalShow(false)}
								currentMeeting={currentMeeting}
							/>
							<Button
								variant="outline-danger"
								className="d-flex align-items-center gap-1"
								onClick={() => {
									dispatch(deleteMeeting(meetingId));
									navigate('/google-meetings');
								}}
							>
								<MdDelete size={18} />
								<span className="fw-bold">Delete Meeting</span>
							</Button>
						</div>
					</div>
				</div>
				{currentMeetingCalls?.length > 0 && (
					<div className="col-4 p-3 d-flex gap-3 flex-wrap justify-content-center border border-dark rounded bg-white">
						{currentMeetingCalls?.map((call, index) => (
							<div key={call?._id} className="border border-dark rounded p-2">
								<div className="h5">Call {index + 1}</div>
								<div className="h6">
									<a href={call?.recordingUrl}>Recording Link</a>
								</div>
								<div className="h6">
									{dayjs(call?.meetingStartTime).format('MMMM-DD, YYYY')}
								</div>
								<div className="h6">
									{dayjs(call?.meetingStartTime).format('hh:mm a')} -{' '}
									{dayjs(call?.meetingEndTime).format('hh:mm a')}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default VirtualParticipantMeeting;
