import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	meetings: [],
	loading: false,
	error: null,
	currentMeeting: null,
	currentMeetingCalls: [],
};

const virtualParticipantMeetingSlice = createSlice({
	name: 'virtualParticipantMeeting',
	initialState,
	reducers: {
		fetchMeetingsStart: (state) => {
			state.loading = true;
		},
		fetchMeetingsSuccess: (state, action) => {
			state.loading = false;
			state.meetings = action.payload;
		},
		fetchMeetingsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		fetchCurrentMeetingStart: (state) => {
			state.loading = true;
		},
		fetchCurrentMeetingSuccess: (state, action) => {
			state.loading = false;
			state.currentMeeting = action.payload;
		},
		fetchCurrentMeetingFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		fetchCurrentMeetingCallsStart: (state) => {
			state.loading = true;
		},
		fetchCurrentMeetingCallsSuccess: (state, action) => {
			state.loading = false;
			state.currentMeetingCalls = action.payload;
		},
		fetchCurrentMeetingCallsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		addMeetingStart(state) {
			state.loading = true;
			state.error = null;
		},
		addMeetingSuccess(state, action) {
			state.loading = false;
			state.meetings.push(action.payload);
		},
		addMeetingFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateMeetingStart(state) {
			state.loading = true;
			state.error = null;
		},
		updateMeetingFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		deleteMeetingStart(state) {
			state.loading = true;
			state.error = null;
		},
		deleteMeetingFailure(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchMeetingsStart,
	fetchMeetingsSuccess,
	fetchMeetingsFailure,
	fetchCurrentMeetingStart,
	fetchCurrentMeetingSuccess,
	fetchCurrentMeetingFailure,
	fetchCurrentMeetingCallsStart,
	fetchCurrentMeetingCallsSuccess,
	fetchCurrentMeetingCallsFailure,
	addMeetingStart,
	addMeetingSuccess,
	addMeetingFailure,
	updateMeetingStart,
	updateMeetingFailure,
	deleteMeetingStart,
	deleteMeetingFailure,
} = virtualParticipantMeetingSlice.actions;

export default virtualParticipantMeetingSlice.reducer;
