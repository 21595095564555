import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import getWeekFromDate from '../../utils/getWeekFromDate';
import { updateMeeting } from '../../redux/features/virtual-participant-meeting/action';

toast.configure();
dayjs.extend(utc);
dayjs.extend(timezone);

function UpdateVirtualParticipantMeetingModal({
	show,
	handleClose,
	currentMeeting,
}) {
	const dispatch = useDispatch();
	const [meetingName, setMeetingName] = useState(currentMeeting?.name);
	const [meetLink, setMeetLink] = useState(currentMeeting?.meetingUrl);
	const [meetingDateTime, setMeetingDateTime] = useState(
		dayjs(currentMeeting?.meetingDateTime)
	);
	const [repeat, setRepeat] = useState(currentMeeting?.repeat);

	const handleDateChange = (e) => {
		const newDate = dayjs(e.target.value);
		setMeetingDateTime(newDate);
	};

	const handleUpdateMeeting = (e) => {
		e.preventDefault();
		if (meetingName === '' || meetLink === '' || meetingDateTime === '') {
			toast.error('All fields are required.');
			return;
		}
		dispatch(
			updateMeeting(currentMeeting?._id, {
				name: meetingName,
				meetingUrl: meetLink,
				meetingDateTime: meetingDateTime.utc().format(),
				timezone: dayjs.tz.guess(),
				repeat,
			})
		);
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Update Meeting</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group className="mb-3" controlId="meetLink">
					<Form.Label>Meeting Name</Form.Label>
					<Form.Control
						value={meetingName}
						onChange={(e) => setMeetingName(e.target.value)}
						required
						placeholder="Enter meeting name"
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="meetLink">
					<Form.Label>Google Meet Link</Form.Label>
					<Form.Control
						value={meetLink}
						onChange={(e) => setMeetLink(e.target.value)}
						required
						placeholder="e.g. https://meet.google.com/..."
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="dateTime">
					<Form.Label>Date and Time</Form.Label>
					<Form.Control
						type="datetime-local"
						value={meetingDateTime.format('YYYY-MM-DDTHH:mm')}
						onChange={handleDateChange}
					/>
				</Form.Group>
				<Form.Group controlId="repeat">
					<Form.Label>Repeat</Form.Label>
					<Form.Select
						value={repeat}
						onChange={(e) => setRepeat(e.target.value)}
					>
						<option value="none">Does not repeat</option>
						<option value="daily">Daily</option>
						<option value="weekly">
							Weekly on {meetingDateTime.format('dddd')}
						</option>
						<option value="monthly">
							{/* Monthly on the {getWeekFromDate(meetingDateTime)}{' '}
							{meetingDateTime.format('dddd')} */}
							Monthly on {meetingDateTime.format('D')}
						</option>
						<option value="yearly">
							Yearly on {meetingDateTime.format('MMMM D')}
						</option>
						<option value="weekdays">Every Weekday (Monday to Friday)</option>
						{/* Add custom option later */}
					</Form.Select>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<Button
					variant="primary-outline"
					type="button"
					className="rounded-pill"
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button variant="primary" type="submit" onClick={handleUpdateMeeting}>
					Save Meeting
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

UpdateVirtualParticipantMeetingModal.propTypes = {
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	currentMeeting: PropTypes.shape({
		name: PropTypes.string.isRequired,
		meetingUrl: PropTypes.string.isRequired,
		meetingDateTime: PropTypes.string.isRequired,
		repeat: PropTypes.string.isRequired,
	}),
};

UpdateVirtualParticipantMeetingModal.defaultProps = {
	currentMeeting: {
		name: '',
		meetingUrl: '',
		meetingDateTime: '',
		repeat: 'none',
	},
};

export default UpdateVirtualParticipantMeetingModal;
