import React from 'react';
import { Card, Button } from 'react-bootstrap';
import LimelineLogo from '../../images/waveform-blue.svg';

const EmptyState = ({ title, body, actionName, action }) => (
	<div className="d-flex justify-content-center align-items-center">
		<Card style={{ width: '30rem' }} className="text-center">
			<Card.Body>
				<Card.Img
					variant="top"
					src={LimelineLogo}
					style={{
						width: '180px',
						maxHeight: '100px',
					}}
				/>
				<Card.Title className="mt-3">{title}</Card.Title>
				<Card.Text className="mt-3">{body}</Card.Text>
				{actionName && (
					<Button variant="primary" className="mt-3" onClick={action}>
						{actionName}
					</Button>
				)}
			</Card.Body>
		</Card>
	</div>
);

export default EmptyState;
