import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { loadUserDataFromDB } from './redux/features/user/action';
import 'react-toastify/dist/ReactToastify.css';
import FullPageSpinner from './components/FullPageSpinner/FullPageSpinner';
import VirtualParticipantMeetings from './pages/VirtualParticipantMeetings';
import VirtualParticipantMeeting from './pages/VirtualParticipantMeeting';

const Landing = React.lazy(() => import('./pages/Landing'));
const Home = React.lazy(() => import('./pages/Home'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Call = React.lazy(() => import('./pages/Call'));
const Authenticate = React.lazy(() => import('./pages/Authenticate'));
const Meeting = React.lazy(() => import('./pages/Meeting'));
const Agents = React.lazy(() => import('./pages/Agents'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess'));
const Templates = React.lazy(() => import('./pages/Templates'));

ReactGA.initialize('G-302W4YKQRD');
function MainContent() {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		// Log the pageview with the current path
		ReactGA.send({ hitType: 'pageview', page: location.pathname });
	}, [location]);

	useEffect(() => {
		dispatch(loadUserDataFromDB());
	}, [dispatch]);
	return (
		<div>
			<Helmet>
				<title>Limeline</title>
				<meta
					name="description"
					content="AI agents that talk to humans and understand."
				/>
				<meta property="og:title" content="Limeline" />
				<meta
					property="og:description"
					content="AI agents that talk to humans and understand."
				/>
				<meta property="og:url" content="https://limeline.ai" />
				<meta property="og:image" content="https://limeline.ai/og-image.png" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				<meta name="twitter:title" content="Limeline" />
				<meta
					name="twitter:description"
					content="AI agents that talk to humans and understand."
				/>
				<meta name="twitter:url" content="https://limeline.ai" />
				<meta name="twitter:image" content="https://limeline.ai/og-image.png" />
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<ToastContainer />
			<React.Suspense fallback={<FullPageSpinner />}>
				<Routes>
					<Route path="/" element={<Landing />} />
					<Route path="/authenticate" element={<Authenticate />} />
					<Route path="/meeting/:meetingId" element={<Meeting />} />
					<Route path="/call/:meetingId" element={<Call />} />
					<Route path="/home" element={<Home />} />
					<Route path="/agents" element={<Agents />} />
					<Route
						path="/google-meetings"
						element={<VirtualParticipantMeetings />}
					/>
					<Route
						path="/google-meetings/:meetingId"
						element={<VirtualParticipantMeeting />}
					/>
					<Route path="/settings" element={<Settings />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/templates" element={<Templates />} />
					<Route path="/paymentsuccess" element={<PaymentSuccess />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</React.Suspense>
		</div>
	);
}

function App() {
	return (
		<Router>
			<MainContent />
		</Router>
	);
}

export default App;
