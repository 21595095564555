import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/slice';
import meetingReducer from './features/meeting/slice';
import virtualParticipantMeetingReducer from './features/virtual-participant-meeting/slice';
import agentReducer from './features/agent/slice';
import apikeyReducer from './features/apikey/slice';

const store = configureStore({
	reducer: {
		user: userReducer,
		meeting: meetingReducer,
		virtualParticipantMeeting: virtualParticipantMeetingReducer,
		agent: agentReducer,
		apikey: apikeyReducer,
	},
});

export default store;
