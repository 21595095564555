import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Form } from 'react-bootstrap';
import { BiCheck } from 'react-icons/bi';
import { MdOutlineClose } from 'react-icons/md';
import { createSession } from '../../../redux/features/payment/action';

import styles from '../index.module.css';

function Plans() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const [isYearly, setIsYearly] = useState(false);

	const monthlyRates = {
		Starter: 15,
		Pro: 39,
		Growth: 89,
	};

	const planLinks = [
		{
			plan: 'Starter',
			monthly: process.env.REACT_APP_PLAN_STARTER_MONTHLY,
			yearly: process.env.REACT_APP_PLAN_STARTER_YEARLY,
		},
		{
			plan: 'Pro',
			monthly: process.env.REACT_APP_PLAN_PRO_MONTHLY,
			yearly: process.env.REACT_APP_PLAN_PRO_YEARLY,
		},
		{
			plan: 'Growth',
			monthly: process.env.REACT_APP_PLAN_GROWTH_MONTHLY,
			yearly: process.env.REACT_APP_PLAN_GROWTH_YEARLY,
		},
	];

	const newLink = async (priceId) => {
		if (!user.accessToken) {
			window.location.href = '/authenticate';
			return;
		}

		const payload = {
			priceId,
		};

		const response = await dispatch(createSession(payload));
		
		window.location.href = response;
	};

	const getYearlyRate = (monthlyRate) => Math.round(monthlyRate * 0.9); // 10% discount on yearly plans
	return (
		<Container fluid>
			<div className={styles.toggleContainer}>
				<span className={isYearly ? '' : styles.toggleLabelActive}>
					Monthly
				</span>
				<Form.Check
					type="switch"
					id="custom-switch"
					label=""
					checked={isYearly}
					onChange={() => setIsYearly(!isYearly)}
					className={styles.toggleSwitch}
				/>
				<span className={isYearly ? styles.toggleLabelActive : ''}>
					Yearly (10% off)
				</span>
			</div>

			<div className={styles.pricing}>
				<div className={styles.plan}>
					<h2>Starter</h2>
					<div className={styles.price}>
						{isYearly
							? `$${getYearlyRate(monthlyRates.Starter)}/month`
							: `$${monthlyRates.Starter}/month`}
					</div>
					<ul className={styles.features}>
						<li>
							<BiCheck /> 100 minutes of meeting time per month
						</li>
						<li>
							<BiCheck /> Default AI Agent
						</li>
						<li>
							<BiCheck /> White labelled call pages
						</li>
						<li style={{ color: '#929292' }}>
							<MdOutlineClose style={{ color: '#929292' }} /> No API access
						</li>
						<li style={{ color: '#929292' }}>
							<MdOutlineClose style={{ color: '#929292' }} /> No priority
							support
						</li>
					</ul>
					<Button
						variant="primary"
						className="mt-3"
						onClick={() => {
							if (isYearly) {
								newLink(planLinks[0].yearly);
							} else {
								newLink(planLinks[0].monthly);
							}
						}}
					>
						Get Started
					</Button>
				</div>
				<div className={`${styles.plan} ${styles.popular}`}>
					<span>Most Popular</span>
					<h2>Pro</h2>
					<div className={styles.price}>
						{isYearly
							? `$${getYearlyRate(monthlyRates.Pro)}/month`
							: `$${monthlyRates.Pro}/month`}
					</div>
					<ul className={styles.features}>
						<li>
							<BiCheck /> 400 minutes of meeting time per month
						</li>
						<li>
							<BiCheck /> 10 AI Agents
						</li>
						<li>
							<BiCheck /> White labelled call pages
						</li>
						<li>
							<BiCheck /> API to create and manage meetings
						</li>
						<li style={{ color: '#929292' }}>
							<MdOutlineClose style={{ color: '#929292' }} /> No priority
							support
						</li>
					</ul>
					<Button
						variant="primary"
						className="mt-3"
						onClick={() => {
							if (isYearly) {
								newLink(planLinks[1].yearly);
							} else {
								newLink(planLinks[1].monthly);
							}
						}}
					>
						Get Started
					</Button>
				</div>
				<div className={styles.plan}>
					<h2>Growth</h2>
					<div className={styles.price}>
						{isYearly
							? `$${getYearlyRate(monthlyRates.Growth)}/month`
							: `$${monthlyRates.Growth}/month`}
					</div>
					<ul className={styles.features}>
						<li>
							<BiCheck /> 800 minutes of meeting time per month
						</li>
						<li>
							<BiCheck /> Unlimited AI Agents
						</li>
						<li>
							<BiCheck /> White labelled call pages
						</li>
						<li>
							<BiCheck /> API to create and manage meetings
						</li>
						<li>
							<BiCheck /> Priority support
						</li>
					</ul>
					<Button
						variant="primary"
						className="mt-3"
						onClick={() => {
							if (isYearly) {
								newLink(planLinks[2].yearly);
							} else {
								newLink(planLinks[2].monthly);
							}
						}}
					>
						Get Started
					</Button>
				</div>
			</div>
		</Container>
	);
}

export default Plans;
