import { toast } from 'react-toastify';
import {
	fetchMeetingsStart,
	fetchMeetingsSuccess,
	fetchMeetingsFailure,
	fetchCurrentMeetingStart,
	fetchCurrentMeetingSuccess,
	fetchCurrentMeetingFailure,
	fetchCurrentMeetingCallsStart,
	fetchCurrentMeetingCallsSuccess,
	fetchCurrentMeetingCallsFailure,
	addMeetingStart,
	addMeetingSuccess,
	addMeetingFailure,
	updateMeetingStart,
	updateMeetingFailure,
	deleteMeetingStart,
	deleteMeetingFailure,
} from './slice';
import fetchFromApi from '../../../services/apiService';

export const fetchMeetings = () => async (dispatch) => {
	dispatch(fetchMeetingsStart());
	try {
		const { data, error } = await fetchFromApi(
			'/virtual-participant-meeting',
			'GET'
		);
		if (error) throw new Error(error);
		dispatch(fetchMeetingsSuccess(data));
	} catch (error) {
		dispatch(fetchMeetingsFailure(error.message));
	}
};

export const fetchCurrentMeeting = (id) => async (dispatch) => {
	dispatch(fetchCurrentMeetingStart());
	try {
		const { data, error } = await fetchFromApi(
			`/virtual-participant-meeting/${id}`,
			'GET'
		);
		if (error) throw new Error(error);
		dispatch(fetchCurrentMeetingSuccess(data));
	} catch (error) {
		dispatch(fetchCurrentMeetingFailure(error.message));
	}
};

export const fetchCurrentMeetingCalls = (id) => async (dispatch) => {
	dispatch(fetchCurrentMeetingCallsStart());
	try {
		const { data, error } = await fetchFromApi(
			`/virtual-participant-call/${id}`,
			'GET'
		);
		if (error) throw new Error(error);
		dispatch(fetchCurrentMeetingCallsSuccess(data));
	} catch (error) {
		dispatch(fetchCurrentMeetingCallsFailure(error.message));
	}
};

export const addMeeting = (meetingData) => async (dispatch) => {
	dispatch(addMeetingStart());
	try {
		// Make API call to add meeting
		const { data, error } = await fetchFromApi(
			'/virtual-participant-meeting',
			'POST',
			meetingData
		);
		if (error) {
			throw new Error('Failed to add meeting');
		}
		dispatch(addMeetingSuccess(data));
		toast.success('Meeting added successfully');
	} catch (error) {
		toast.error('Failed to add meeting');
		dispatch(addMeetingFailure(error.message));
	}
};

export const updateMeeting = (id, updatedMeetingData) => async (dispatch) => {
	dispatch(updateMeetingStart());
	try {
		// Make API call to update meeting
		const { data, error } = await fetchFromApi(
			`/virtual-participant-meeting/${id}`,
			'PUT',
			updatedMeetingData
		);
		if (error) {
			throw new Error('Failed to update meeting');
		}
		dispatch(fetchCurrentMeeting(id));
		toast.success('Meeting updated successfully');
	} catch (error) {
		toast.error('Failed to update meeting');
		dispatch(updateMeetingFailure(error.message));
	}
};

export const joinMeetingNow = (meetingId) => async () => {
	try {
		// Make API call to join meeting
		const { data, error } = await fetchFromApi(
			`/virtual-participant-meeting/join-meeting-now`,
			'POST',
			{ meetingId }
		);
		if (error) {
			throw new Error('Failed to join meeting');
		}
		toast.success('Joining meeting now...');
	} catch (error) {
		toast.error('Failed to join meeting');
	}
};

export const deleteMeeting = (id) => async (dispatch) => {
	dispatch(deleteMeetingStart());
	try {
		// Make API call to delete meeting
		const { data, error } = await fetchFromApi(
			`/virtual-participant-meeting/${id}`,
			'DELETE'
		);
		if (error) {
			throw new Error('Failed to delete meeting');
		}
		dispatch(fetchMeetings());
		toast.success('Meeting deleted successfully');
	} catch (error) {
		toast.error('Failed to delete meeting');
		dispatch(deleteMeetingFailure(error.message));
	}
};
